import {Container} from "src/components";
import {Components} from 'eyecons-components'
import React from "react";
import {App} from "src/layouts";

export default function NotFound() {
    return (
        <Container size={'small'} className={'text-center flex flex-col gap-4'}>
            <Components.Texts.Heading>404</Components.Texts.Heading>
            <Components.Texts.Primary className={'text-gray-700'}>Page not found</Components.Texts.Primary>
            <Components.Texts.Primary className={'text-gray-700'}>The requested url does not exist on this
                server</Components.Texts.Primary>
        </Container>
    )
}

NotFound.getLayout = function getLayout(page: React.ReactElement) {
    return (
        <App>
            <NotFound/>
        </App>
    )
}